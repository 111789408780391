import React from 'react'
import {graphql, StaticQuery} from 'gatsby'
import ArticlePreview from '../../templates/articlePreview'
import CategoryTitle from "../helpers/categoryTitle";
import {isMobile} from 'react-device-detect'
import cx from 'classnames'
/*import {adDisplayDelay, delayMultiplicationFactor} from "../../utils/articleUtil"*/

class PopularPosts extends React.Component {

    constructor(props) {
        super(props);
        this.className = cx(
          "hidden w-[305px] min-h-[423px] lg:flex flex-col justify-between p-4 pb-8 bg-gray-100",
          props.className
        )
      this.state = {}
        /*this.state = {
          travelInUkAffiliate: ['travel-in-the-uk'].includes(props.affiliateCategory),
          travelAffiliate: ['themed-holidays', 'best-travel-destinations', 'solo-travel', 'adventure-holidays'].includes(props.affiliateCategory)
        }*/
    }

    componentDidMount() {
      try {
        googletag.cmd.push(function () {
          googletag.display('div-gpt-ad-1662966872481-0');
        });
      } catch (e) {
        console.log(e);
      }
        /*setTimeout(() => {
            try {
                googletag.cmd.push(function () {
                    googletag.display('div-gpt-ad-1662966872481-0');
                });
            } catch (e) {
                console.log(e);
            }
        }, adDisplayDelay() * delayMultiplicationFactor(isMobile));*/
    }

    renderItems() {
        return <StaticQuery
            query={graphql`
            query PopularPostsQuery {
              video: allDatoCmsArticle(
                    filter: {category: {elemMatch: {originalId: {in: ["39161838"]}}}, website: {elemMatch: {name: {eq: "at"}}}}
                    limit: 1
                    skip: 30
                ) {
                    nodes {
                        ...ArticleFieldsPluginWithPortraitImage
                    }
                }
                nonVideo: allDatoCmsArticle(
                    filter: {category: {elemMatch: {originalId: {nin: ["39161838"]}}}, website: {elemMatch: {name: {eq: "at"}}}}
                    limit: 3
                    skip: 30
                ) {
                    nodes {
                        ...ArticleFieldsPluginWithPortraitImage
                    }
                }
            }
        `}
            render={data =>
                data.nonVideo.nodes.concat(data.video.nodes).slice(0, 3)
                    .map((article, index) => {
                    return <ArticlePreview
                      key={`top-right-${index}`} data={article}
                      className="flex w-full"
                      imageClass="mb-0 mr-2 float-left clear-both"
                      gatsbyImageClass="w-[70px] h-[70px]"
                      headingClass="font-sans font-semibold leading-snug text-lg mb-0 line-clamp l-c-3"
                      noFlag={true}
                      noBody={true}
                      noAuthor={true}
                      wrappedImg={false}
                      dateAsFlag={true}
                      titleAuthorDisplayClass={"flex-col-reverse"}
                      publishedAtFormat={"mmmm dd, yyyy"}/>
                })
            }
        />
    }

    render() {
        return !isMobile && <div className={"popular-posts article-popular relative ml-4 min-w-[305px]"}>
            <div className={this.className} data-datocms-noindex>
                <CategoryTitle title="Popular Posts"/>
                {this.renderItems()}
            </div>
            {this.state.travelAffiliate && <div className={"text-center my-4"}>
              {/*<a className="affiliate-link affiliate-network-awin affiliate-vendor-wendywu"
                 href="https://www.awin1.com/cread.php?s=2926501&amp;v=8998&amp;q=417174&amp;r=892181"
                 rel="noopener sponsored" target="_blank">
                <img
                src="https://www.awin1.com/cshow.php?s=2926501&amp;v=8998&amp;q=417174&amp;r=892181 "
                width="300" height="250"/>
              </a>*/}
              <a
                className="affiliate-link affiliate-source-atwebsite affiliate-network-awin affiliate-vendor-flightcentre"
                href="https://www.awin1.com/cread.php?s=3147717&v=25881&q=437712&r=892181"
                rel="sponsored" target="_blank"><img
                src="https://www.awin1.com/cshow.php?s=3147717&v=25881&q=437712&r=892181"
                width="300" height="250"/></a>
              {/*<a
                className="affiliate-link affiliate-source-atwebsite affiliate-network-awin affiliate-vendor-flightcentre"
                href="https://www.awin1.com/cread.php?s=3134107&v=25881&q=436648&r=892181"
                rel="sponsored" target="_blank"><img
                src="https://www.awin1.com/cshow.php?s=3134107&v=25881&q=436648&r=892181"
                width="300" height="250" alt=""/></a>*/}
            </div>}
            {this.state.travelInUkAffiliate && <div className={"text-center my-4"}>
              <a className="affiliate-link affiliate-network-awin affiliate-vendor-parkholidaysuk"
                 href=" https://www.awin1.com/cread.php?s=2318387&amp;v=1738&amp;q=103693&amp;r=892181"
                 rel="sponsored" target="_blank">
                <img
                  src=" https://www.awin1.com/cshow.php?s=2318387&v=1738&q=103693&r=892181" width="300" height="250"/></a>
            </div>}
            <div className="ad-container mb-2" data-datocms-noindex>
              <div className="advertisement-text">Advertisement</div>
              <div id='div-gpt-ad-1662966872481-0'></div>
            </div>
        </div>
    }
}

export default PopularPosts;
